@font-face {
    font-family: 'ThaLeah';
    src: url('../ThaleahFat.ttf') format("truetype")
}

.home-container {
    background: rgb(45, 46, 59);
    height: 87vh;
}

.home {
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Thaleah;
    font-size: 32px;
}
.linkButton {
    color: white;
    background-color: rgba(12, 17, 22, 0.644);
    width: 100px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    margin: 3vh;
    display:flex;
    justify-content: center;
    align-items: center;
}

.linkButton:hover{
    transform: translate(0px, 2px);
}

a{
    font-family: thaleah;
    font-size: 24px;
}
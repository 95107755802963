.modal-metamask {
    background-color: #f5861e;
    text-align: center;
    width: 80%;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 12px;
}

.connect {
    margin: 8px;
    font-size: 24px;
    margin: 24px 24px
}
.player{
    width: 30px;
    height: 75px;
    margin-bottom: -3px;
}

.firstPodium{
    text-align: center;
    background-color: #fdcb5d;
    width: 45px;
    height: 60px;
}

.secondPodium{
    text-align: center;
    background-color: rgb(187, 187, 187);
    width: 45px;
    height: 50px;
}

.thirdPodium{
    text-align: center;
    background-color: rgb(128, 87, 0);
    width: 45px;
    height: 40px;

}

.podium{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 42px;
    color: white;
}
.button {
    color: white;
    background-color: rgba(12, 17, 22, 0.644);
    width: 100px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    margin: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.button:hover{
    transform: translate(0px, 2px);
}

table{
    background:rgba(12, 17, 22, 0.644);
    border-radius: 10px;
    font-size: 32px;
    font-family: thaleah;
    color: white;
}

th, td{
    font-size: 32px;
    padding: 1px;
    padding-left: 10px;
}

p{ 
    color: white;
    font-family: thaleah;
    margin:auto;
}

h2{
    color: white;
    font-family: Thaleah;
}

.fadein{
    animation: fadein 1s;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */  
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.history-content {
    display: flex;
    width: 42vh;
    height: 60vh;
    background-color: rgba(46, 46, 46, 0.952);
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    overflow: auto;
}

.history-content-container {
    width: 36vh;
    height: 55vh;
    overflow: auto;
}

.history-item-container{
    width: 100%;
    text-align: center;
    margin: 4px;
}

.history-table {
    background: none;
    height: 100%;
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
}

.history-tbody {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.history-item{
    padding: 8px;
    background: rgba(12, 17, 22, 0.644);
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.playerTableDiv {
    width: 100%;
    display: flex;
    flex-Direction: row;
    align-Items: baseline;
    justify-Content: center;
    align-self: stretch;
}

.playerTable{
    max-height: 250px;
    height: 250px;
    display: block;
    overflow-y: scroll;
    margin: 5px;
    overflow-x: hidden;
    max-width: clamp(200px, 90%, 600px );
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
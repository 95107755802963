.button {
    color: white;
    background-color: rgba(12, 17, 22, 0.644);
    width: 100px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    margin: 3vh;
    transition: 0.5s;
}

.holeImg{
    width:90%;
    /* Original image size is 400x400 */
    max-width: 400px;
    max-height: 250px;
    margin-top: -60px;    
}

.title{
    font-size: 48px;
    margin-top: 15px;
}
